<template>
  <div class="question-result-overall">
    <div class="questionnaire-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/recommend.png`)" fit="contain"></el-image>
        <div class="head-title">AI生涯规划推荐</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <div class="result-body">
      {{ resultData }}
    </div>
    <div class="body-bottom">
      <el-button class="restart-button" @click="restartButton">重做</el-button>
    </div>
  </div>
</template>

<script>
import { getResult } from "../../../request/api";

export default {
  data () {
    return {
      questionnaire_result_id: undefined,
      student_questionnaire_classify_id: undefined,
      resultData: ''
    }
  },
  methods: {
    sendExit () {
      this.$emit('update-value')
    },
    restartButton() {
      this.$router.push({
        path: "/questionnaire",
        query: {
          student_questionnaire_classify_id: this.student_questionnaire_classify_id,
        },
      });
    },
    async init() {
      if (this.questionnaire_result_id === undefined) return
      try {
        const res = await getResult(this.questionnaire_result_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
        }

        this.resultData = res.data.data
      } catch(err) {
        this.init()
      }
    }
  },
  created () {
    this.questionnaire_result_id = this.$route.query.questionnaire_result_id
    this.student_questionnaire_classify_id = this.$route.query.student_questionnaire_classify_id
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.question-result-overall {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .result-body {
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
    padding: 30px;
    box-sizing: border-box;
    white-space: pre-wrap;
    margin-bottom: 10px;
  }
  .body-bottom {
    height: 8%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    .restart-button {
      background-color: #409eff;
      color: #fff;
      width: 90px;
      border: none;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #66b1ff;
      }
    }
  }
  .questionnaire-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>